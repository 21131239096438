.ambient {
  background: linear-gradient(
    -30deg,
    #f6f6f6,
    #ffe8c7,
    #ffc2fc,
    #dbfaff,
    #ffffff
  );
  background-size: 400% 400%;
  animation: gradient 12s ease infinite;
}

.full-h {
  height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
