.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.tiptap-variable {
  border: 1px solid #edeeec;
  border-radius: 0.4rem;
  box-decoration-break: clone;
  padding: 0.2rem 0.4rem;
  background: #f6f6f5;
  font-size: 12px;
  margin-right: 4px;
}

.tiptap-variable.ProseMirror-selectednode {
  background: #adb5bd;
}

.tiptap.mentionNode {
  background-color: #cfa5fa;
  border-radius: 0.4rem;
  box-decoration-break: clone;
  color: #7014c7;
  padding: 0.1rem 0.3rem;
}
