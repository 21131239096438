@tailwind base;
@tailwind components;
@tailwind utilities;

.square {
  width: 64px;
  height: 55.43px;
  background: linear-gradient(
    -30deg,
    #f6f6f6,
    #ffe8c7,
    #ffc2fc,
    #dbfaff,
    #ffffff
  );
  background-size: 400% 400%;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  animation:
    gradient 6s ease infinite,
    rotation 3s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes rotation {
  0% {
    transform: translate(0, 0) rotate(90deg);
  }
  50% {
    transform: translate(0px, 0) rotate(270deg);
  }
  100% {
    transform: translate(0, 0) rotate(90deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
