@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  font-family:
    Helvetica Neue,
    Inter,
    -apple-system,
    Roboto,
    BlinkMacSystemFont,
    SF Pro,
    Segoe UI,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  padding: 0;
  margin: 0;
  @apply bg-pavlov-bg;
}
.btn-lg {
  @apply animate rounded-full px-4 py-3 text-center text-sm font-semibold tracking-tight flex align-middle items-center justify-center;
}

.btn-md {
  @apply animate rounded-full px-4 py-2 text-center text-[13px] font-semibold tracking-tight flex align-middle items-center justify-center;
}

.btn-sm {
  @apply animate rounded-full px-4 py-2 text-center text-xs font-semibold tracking-tight flex align-middle items-center justify-center;
}

a {
  -o-transition: 0.5s ease;
  -ms-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

.animate {
  @apply transition duration-500 ease-in-out;
}

label {
  font-size: 13px;
  line-height: 20px;
  @apply mb-2 block font-medium text-body-text;
}

h2 {
  font-size: 20px;
  line-height: 24px;
  @apply font-bold tracking-tight text-body-text;
}

h3 {
  font-size: 16px;
  line-height: 20px;
  @apply font-bold tracking-normal text-body-text;
}

span.tag {
  text-transform: uppercase;
  font-size: 9px;
  line-height: 12px;
  @apply ml-2 inline-flex items-center rounded-full bg-blue-50 px-3 py-1 font-normal tracking-wider text-blue-700;
}

/* __next div limits full height for some reason */
body > div:first-child,
div#__next,
div#__next > div {
  height: 100%;
}

[type="text"]:focus {
  outline: none;
  box-shadow: none;
  border-color: inherit;
}

select:focus {
  outline: none;
  box-shadow: none;
  border-color: inherit;
}

.engagement-circle-icon {
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

.spinner {
  border-top: 3px solid #ffffff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  @apply border-t border-body-text;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(32px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation: fadeInUp 0.8s ease-out;
}

/* Default styles for agent generated AI Health Summary */
.ai_health_summary h1 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.ai_health_summary h2 {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.ai_health_summary p {
  margin-bottom: 0.5rem;
}

.ai_health_summary ul {
  list-style-type: disc;
  margin-bottom: 0.5rem;
}

.ai_health_summary ul li {
  margin-left: 1.25rem;
}

.ai_health_summary hr {
  margin: 1.25rem 0;
}

.ai_health_summary ol {
  list-style-type: decimal;
  margin-bottom: 0.5rem;
}

.ai_health_summary ol li {
  margin-left: 1.25rem;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-in {
  animation: slideIn 0.5s ease-out forwards;
}
