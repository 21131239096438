.CampaignButton {
  transition:
    background-position 0.45s ease-in-out 0s,
    box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    border-color 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    color 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    opacity 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    background-color 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  background: linear-gradient(
    270deg,
    #1e293b 0%,
    #545c69 50%,
    #ffe8c7 75%,
    #ffc2fc 100%
  );
  background-size: 360% 100%;
  background-position: 99% 0;
  @apply text-white;
}

.CampaignButton:hover,
.CampaignButton:active {
  background-position: 0 0;
  @apply text-body-text;
}

.BehaviorButton {
  transition:
    background-position 0.45s ease-in-out 0s,
    box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    border-color 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    color 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    opacity 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    background-color 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  background: linear-gradient(
    270deg,
    #1e293b 0%,
    #545c69 50%,
    #ffc2fc 75%,
    #dbfaff 100%
  );
  background-size: 360% 100%;
  background-position: 99% 0;
  @apply text-white;
}

.BehaviorButton:hover,
.BehaviorButton:active {
  background-position: 0 0;
  @apply text-body-text;
}

.LifecycleStageButton {
  transition:
    background-position 0.45s ease-in-out 0s,
    box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    border-color 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    color 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    opacity 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    background-color 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  background: linear-gradient(
    270deg,
    #1e293b 0%,
    #545c69 50%,
    #ffc2fc 75%,
    #dbfaff 100%
  );
  background-size: 360% 100%;
  background-position: 99% 0;
  @apply text-white;
}

.LifecycleStageButton:hover,
.LifecycleStageButton:active {
  background-position: 0 0;
  @apply text-body-text;
}

.PauseButton {
  transition: all 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  background: linear-gradient(
      270deg,
      #dededd 0%,
      #e8e8e7 50%,
      #ffe8c7 75%,
      #ffc2fc 100%
    )
    0 0 / 400% 100%;
  @apply text-body-text;
}

.BehaviorPauseButton {
  transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  background: linear-gradient(
      270deg,
      #dededd 0%,
      #e8e8e7 50%,
      #ffe1fe 75%,
      #effdff 100%
    )
    0 0 / 400% 100%;
  @apply text-body-text;
}

.PauseButton:hover,
.BehaviorPauseButton:hover {
  background-position: 99% 0;
}

.PauseButton:active,
.BehaviorPauseButton:active {
  background: #1e293b;
  color: white;
}

.RotateIcon {
  transition-property: transform;
  transition-duration: 800ms;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  @apply flex items-center justify-center flex-col mr-1.5;
}

button:hover .RotateIcon {
  transform: rotate(135deg);
}

.GrowIcon {
  transition: transform 800ms cubic-bezier(0.215, 0.61, 0.355, 1);
  @apply flex items-center justify-center flex-col mr-1.5;
}

button:hover .GrowIcon {
  transform: scale(1.2);
}

.StaticIcon {
  @apply flex mr-1.5;
}

.AiDrawerButton {
  transition: all 1.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  background: linear-gradient(
      330deg,
      #ffffff 0%,
      #ffdefd 20%,
      #ffe8c7 40%,
      #dbfaff 60%,
      #ffffff 100%
    )
    0 0 / 800% 100%;
  @apply flex items-stretch text-[13px] leading-5 px-5 py-2.5 text-body-text rounded-full font-medium hover:shadow-md;
}
.AiDrawerButton:hover,
.AiDrawerButton:active {
  background-position: 99% 0;
  box-shadow:
    0px 0px 8px rgba(255, 232, 200, 0.15),
    -6px -6px 12px rgba(253, 133, 133, 0.15),
    6px 6px 12px rgba(255, 194, 252, 0.3);
  @apply text-body-text;
}
